.App-header {
  background-color: #FFF;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  font-family: HelveticaNeue-Light;
  line-height: 1.5;
  background-color: #ECFAFF;
  padding: 0 2rem;
}

img {
  max-width: 100%;
  display: block;
}

input {
  appearance: none;
  border-radius: 0;
}

.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-top: 10px;
}

.card {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(#999, .25);
  padding: .75rem;
}

.card-image {
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 25%;
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 0 5%;
  position: relative;
}

.card-heading {
  position: absolute;
  left: 10%;
  top: 15%;
  right: 10%;
  font-size: 1.75rem;
  font-weight: 700;
  color: #735400;
  line-height: 1.222;
  small {
    display: block;
    font-size: .75em;
    font-weight: 400;
    margin-top: .25em;
  }
}

.card-form {
  padding: 2rem 1rem 0;
}

.input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
  font-family: HelveticaNeue-Light;
  &+.input {
    margin-top: 1.5rem;
  }
}

.input-label {
  color: #DFDFDF;
  position: absolute;
  top: 1.5rem;
  transition: .25s ease;
  font-family: HelveticaNeue-Light;
}

.input-field {
  border: 0;
  z-index: 1;
  font-family: HelveticaNeue-Light;
  background-color: transparent;
  border-bottom: 2px solid #eee; 
  font: inherit;
  font-size: 1.125rem;
  padding: .25rem 0;
  &:focus, &:valid {
    outline: 0;
    border-bottom-color: #6658d3;
    &+.input-label {
      color: #6658d3;
      transform: translateY(-1.5rem);
    }
  }
}

.action {
  margin-top: 2rem;
}

.action-button {
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 100%;
  font-weight: 500;
  font-family: HelveticaNeue-Light;
  background-color: #43B8F7;
  border-radius: 6px;
  color: #FFF;
  border: 0;
  &:focus {
    outline: 0;
  }
}

.card-info {
  padding: 1rem 1rem;
  text-align: center;
  font-size: .875rem;
  color: #8597a3;
  a {
    display: block;
    color: #6658d3;
    text-decoration: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.h100{
  font-family: HelveticaNeue-Light;
  font-size:24px;
  color: #000;
}

.app {
  text-align: left;
}

/* Style the header with a grey background and some padding */
.header {
  padding: 5px 5px;
}

/* Style the header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-size: 18px;
  line-height: 20px;
  border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active/current link*/
.header a.active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}

.row {
  align-items: left;
  margin: auto;
  width: 93%;
}

.blueButton {
  background-color:#43B8F7;
  border:1px solid #43B8F7;
  cursor:pointer;
  color:#ffffff;
  font-family: HelveticaNeue-Light;
  padding:16px 31px;
  margin: 5px;
  marker-left: 20px;
  text-decoration:none;
  border-radius:7px;
  font-size:0.875;
  word-wrap: break-word;
  max-width: 100%;
}

.greenButton {
  background-color:#7FE683;
  border:1px solid #7FE683;
  cursor:pointer;
  color:#ffffff;
  font-family: HelveticaNeue-Light;
  padding:16px 31px;
  margin: 5px;
  marker-left: 20px;
  text-decoration:none;
  border-radius:28px;
  font-size:0.875;
  word-wrap: break-word;
  max-width: 100%;
}

.whiteButton {
  background-color:#FFF;
  border:1px solid #000;
  cursor:pointer;
  color:#000;
  font-family: HelveticaNeue-Light;
  padding:16px 31px;
  margin: 5px;
  marker-left: 20px;
  text-decoration:none;
  border-radius:7px;
  font-size:0.875;
  word-wrap: break-word;
  max-width: 100%;
}

.blueButton:hover {
  background-color:#53BEF7;
}


.loader{
  align-items: center;
  justify-content: center;
  text-align: center;
  background:rgba(255,255,255,.8);
  padding-top:20px;
  z-index:99999;
}

.loader div{
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
